
import { defineComponent } from "vue";
import { loadFull } from "tsparticles";
import { IonIcon } from "@ionic/vue";
import { cart, reorderThreeOutline } from "ionicons/icons";

export default defineComponent({
  name: "HeaderGenericComponent",
  components: {
    IonIcon,
  },

  data() {
    return {
      reorderThreeOutline,
      cart,
      showHeaderMenu: false,
    };
  },
  methods: {
    particlesInit: async (engine: any) => {
      await loadFull(engine);
    },
  },
});
