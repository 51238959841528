import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/chi-siamo',
    redirect: '/'
  },
  {
    path: '/a-chi-ci-rivolgiamo',
    name: 'A chi ci rivolgiamo',
    component: () => import('@/views/AChiCiRivolgiamoPage.vue')
  },
  {
    path: '/libri',
    name: 'Libri',
    component: () => import('@/views/LibriPage.vue')
  },
  {
    path: '/corsi',
    name: 'Corsi',
    component: () => import('@/views/CorsiPage.vue')
  },
  {
    path: '/recensioni',
    name: 'Recensioni',
    component: () => import('@/views/RecensioniPage.vue')
  },
  {
    path: '/testimonianze',
    name: 'Testimonianze',
    component: () => import('@/views/TestimonianzePage.vue')
  },
  {
    path: '/testimonianze/:id',
    name: 'ModificaTestimonianza',
    component: () => import('@/views/testimonianze/edit.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/testimonianze/nuova',
    name: 'NuovaTestimonianza',
    component: () => import('@/views/testimonianze/add.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/software',
    name: 'Software',
    component: () => import('@/views/SoftwarePage.vue')
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: () => import('@/views/ContattiPage.vue')
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: () => import('@/views/SponsorPage.vue')
  },
  {
    path: '/recensioni',
    name: 'Recensioni',
    component: () => import('@/views/RecensioniPage.vue')
  },
  {
    path: '/carrello',
    name: 'Carrello',
    component: () => import('@/views/CarrelloPage.vue')
  },
  {
    path: '/conferma',
    name: 'Conferma ordine',
    component: () => import('@/views/ConfirmOrderPage.vue')
  },
  {
    path: '/ordine-confermato',
    name: 'OrdineConfermato',
    component: () => import('@/views/OrderConfirmed.vue')
  },
  {
    path: '/annulla',
    name: 'Annulla ordine',
    component: () => import('@/views/CancelOrderPage.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/AccountPage.vue'),
    meta: {
      loginRequired: true
    },
    children: [
      {
        path: '',
        name: 'Profilo',
        component: () => import('@/views/account/default.vue')
      },
      {
        path: 'impostazioni/indirizzo',
        component: () => import('@/views/account/address.vue')
      },
      {
        path: 'impostazioni/password',
        component: () => import('@/views/account/password.vue')
      },
      {
        path: 'impostazioni/email',
        component: () => import('@/views/account/email.vue')
      },
      {
        path: 'archivio/corsi',
        component: () => import('@/views/account/courses.vue')
      },
      {
        path: 'archivio/corsi/:id',
        component: () => import('@/views/account/coursesReview.vue')
      },
      {
        path: 'archivio/ordini',
        component: () => import('@/views/account/orders.vue')
      },
      {
        path: 'archivio/ordini/:id',
        component: () => import('@/views/account/ordersReview.vue')
      },
      {
        path: 'gestione/corsi',
        component: () => import('@/views/gestione/CorsiPage.vue')
      },
      {
        path: 'gestione/ordini',
        component: () => import('@/views/gestione/OrdiniPage.vue')
      },
      {
        path: 'gestione/galleria',
        component: () => import('@/views/gestione/gallery/GalleryPage.vue')
      },
      {
        path: 'gestione/galleria/:courseId',
        component: () => import('@/views/gestione/gallery/CourseGalleryPage.vue')
      },
      {
        path: 'gestione/corsi/add',
        component: () => import('@/views/gestione/NewCorsiPage.vue')
      },
      {
        path: 'gestione/corsi/edit/:id',
        component: () => import('@/views/gestione/EditCorsiPage.vue')
      },
      {
        path: 'gestione/moduli/add/:id',
        component: () => import('@/views/gestione/NewModuliPage.vue')
      },
      {
        path: 'gestione/moduli/edit/:id',
        component: () => import('@/views/gestione/EditModuliPage.vue')
      },
      {
        path: 'gestione/lezioni/add/:id',
        component: () => import('@/views/gestione/NewDataPage.vue')
      },
      {
        path: 'gestione/lezioni/edit/:id',
        component: () => import('@/views/gestione/EditDataPage.vue')
      },
      {
        path: 'gestione/lezioni/participants/:id',
        component: () => import('@/views/gestione/ParticipantsDataPage.vue')
      },
      {
        path: '*',
        redirect: ''
      }
    ]
  },
  {
    path: '/reset-password/:authCode',
    name: 'Reset password',
    component: () => import('@/views/ResetPasswordPage.vue')
  },
  {
    path: '/register',
    name: 'Registrati',
    component: () => import('@/views/RegisterPage.vue'),
    meta: {
      loginRequired: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      loginRequired: false
    },
  },
  {
    path: '/lost-password',
    name: 'Lost password',
    component: () => import('@/views/LostPasswordPage.vue'),
    meta: {
      loginRequired: false
    },
  },
  {
    path: '/conferma-account/:authCode',
    name: 'Conferma account',
    component: () => import('@/views/AccountVerifyPage.vue'),
    meta: {
      loginRequired: false
    },
  },
  {
    path: '/gestione',
    name: 'Gestione sito',
    component: () => import('@/views/gestione/CorsiPage.vue'),
    children: [
      {
        path: '/users',
        name: 'Utenti',
        component: () => import('@/views/gestione/CorsiPage.vue'),
        children: [
          {
            path: '/:id',
            name: 'Visualizza dettagli',
            component: () => import('@/views/gestione/CorsiPage.vue')
          }
        ]
      },
      {
        path: 'corsi',
        name: 'Dettagli corsi',
        component: () => import('@/views/gestione/CorsiPage.vue'),
        children: [
          {
            path: 'add',
            name: 'Aggiungi un corso',
            component: () => import('@/views/gestione/CorsiPage.vue'),
          }
        ]
      },
      {
        path: 'lessons',
        name: 'Lezioni',
        component: () => import('@/views/gestione/CorsiPage.vue'),
        children: [
          {
            path: '/add',
            name: 'Aggiungi una lezione',
            component: () => import('@/views/gestione/CorsiPage.vue'),
          },
          {
            path: '/edit/:id',
            name: 'Modifica una lezione',
            component: () => import('@/views/gestione/CorsiPage.vue'),
          }
        ]
      },
      {
        path: '/orders',
        name: 'Ordini',
        component: () => import('@/views/gestione/CorsiPage.vue'),
        children: [
          {
            path: '/edit/:id',
            name: 'Modifica un ordine',
            component: () => import('@/views/gestione/CorsiPage.vue'),
          }
        ]
      },
      {
        path: '/stats',
        name: 'Statistiche',
        component: () => import('@/views/gestione/CorsiPage.vue'),
      },
      {
        path: '*',
        redirect: '/'
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta && 'loginRequired' in to.meta) {
    if (to.meta.loginRequired && !localStorage.getItem('refreshToken'))
      return { name: 'Login' }
    else if (!to.meta.loginRequired && localStorage.getItem('refreshToken'))
      return { name: 'Home' }
  }
})

export default router
