
import { defineComponent } from "vue";
import { loadFull } from "tsparticles";
import HeaderHomeComponent from "@/components/header/home.vue"
import HeaderGenericComponent from "@/components/header/generic.vue"

export default defineComponent({
  name: "HeaderComponent",
  components: {
    HeaderHomeComponent,
    HeaderGenericComponent
  }
});
